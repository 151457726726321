'use client';

import { signIn } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import GoogleIcon from '../icons/GoogleIcon';
import { AuthTypeEnum } from '@/lib/types/general';
import { useLocalStorage } from '@/lib/hooks/useLocalStorage';
import { useState } from 'react';
import SpinLoader from '../general/SpinLoader';

const GoogleSignInButton = () => {
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get('callbackUrl');
  const [, setLastClickedAuthType] = useLocalStorage<AuthTypeEnum | undefined>(
    'lastClickedAuthType',
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClick = () => {
    setLastClickedAuthType(AuthTypeEnum.Google);
    signIn('google', { callbackUrl: callbackUrl || '' });
    setIsLoading(true);
  };

  return isLoading ? (
    <SpinLoader size={{ width: 24, height: 24 }} />
  ) : (
    <button
      onClick={onClick}
      className='color-divider2 flex h-14 w-full cursor-pointer flex-row items-center justify-center gap-4 rounded-lg border bg-white px-4 shadow-sm'
    >
      <GoogleIcon />
      <div className='text-sm font-medium'>Continue with Google</div>
    </button>
  );
};

export default GoogleSignInButton;
