import { PatternReferenceImage } from '@prisma/client';
import { GridData } from './palette';

export enum FloatingToolbarType {
  Drag = 'drag',
  ColorPicker = 'color_picker',
  AddBead = 'add_bead',
  RemoveBead = 'remove_bead',
  FillBead = 'fill_bead',
  ClearFillBead = 'clear_fill_bead',
  Undo = 'undo',
  Redo = 'redo',
}

export type SelectableFloatingToolbarType =
  | FloatingToolbarType.Drag
  | FloatingToolbarType.ColorPicker
  | FloatingToolbarType.AddBead
  | FloatingToolbarType.RemoveBead
  | FloatingToolbarType.FillBead
  | FloatingToolbarType.ClearFillBead;

export enum UnitOfMeasureEnum {
  cm = 'cm',
  mm = 'mm',
  inch = 'inch',
}

export interface Size {
  width: number;
  height: number;
}

export interface Point {
  x: number;
  y: number;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface PatternSize {
  rows: number;
  cols: number;
}

export enum StitchTypeEnum {
  Peyote = 'peyote',
  Brick = 'brick',
  Loom = 'loom',
}

export enum PatternSizePresetEnum {
  BraceletSmall = 'bracelet_small',
  BraceletMedium = 'bracelet_medium',
  BraceletLarge = 'bracelet_large',
  PenWrapSmall = 'pen_wrap_small',
  PenWrapMedium = 'pen_wrap_medium',
  PenWrapLarge = 'pen_wrap_large',
  EarringSmall = 'earring_small',
  EarringMedium = 'earring_medium',
  EarringLarge = 'earring_large',
  NecklaceSmall = 'necklace_small',
  NecklaceMedium = 'necklace_medium',
  NecklaceLarge = 'necklace_large',
}

export enum AuthTypeEnum {
  Unknown = 'unknown',
  Google = 'google',
}

export enum SubscriptionTypeEnum {
  Free = 'free',
  PremiumMonthly = 'monthly',
  PremiumYearly = 'yearly',
}

export interface ConfirmModalOptions {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export interface AlertModalOptions {
  title: string;
  message: string;
  confirmText: string;
  onConfirm?: () => void;
}

export interface WizardModalPosition {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export interface WizardStep {
  title: string;
  message: string;
  getPosition?: (isMobile: boolean) => Promise<WizardModalPosition>;
  triangleClassName?: string;
  selectedContext?: SelectedContextEnum;
}

export enum WizardModalStepEnum {
  Welcome = 0,
  Menu = 1,
  Toolbar = 2,
  Profile = 3,
  Palette = 4,
  PatternSize = 5,
  Canvas = 6,
}

export interface WizardModalOptions extends WizardStep {
  index: number;
}

export interface UploadImage {
  imageUrl: string;
  size: Size;
}

export enum MouseOrTouchPressed {
  None,
  Left,
  Right,
}

export interface MouseOrTouchData {
  button: MouseOrTouchPressed;
  pos: Point;
}

export interface HistoryData {
  past: HistoryDataItem[];
  present: HistoryDataItem;
  future: HistoryDataItem[];
}

export interface HistoryDataItem {
  stitchType: StitchTypeEnum;
  patternSize: PatternSize;
  patternPosition: Point;
  patternZoom: number;
  unitOfMeasureId: UnitOfMeasureEnum;
  grid: GridData;
  referenceImage?: PatternReferenceImage;
}

export enum LocaleEnum {
  enUS = 'en-US',
  itIT = 'it-IT',
}

export enum ImageTransferPreEffectEnum {
  NotSelected = 'not_selected',
  None = 'none',
  BlackAndWhite = 'black_and_white',
  GrayScale = 'gray_scale',
  Sepia = 'sepia',
}

export enum SelectedContextEnum {
  None = 'none',
  Pattern = 'pattern',
  Image = 'image',
}

export enum UploadImageTriggerTypeEnum {
  NewPattern = 'new_pattern',
  UploadImage = 'upload_image',
}

export enum PatternSettingsModalOptionEnum {
  FromScratch = 'from_scratch',
  FromImage = 'from_image',
}

export enum AnalyticEventNameEnum {
  NewPattern = 'new_pattern',
  OpenPattern = 'open_pattern',
  SavePattern = 'save_pattern',
  PrintPDFPattern = 'print_pdf_pattern',
  DownloadPDFPattern = 'download_pdf_pattern',
  UploadImage = 'upload_image',
  ToggleImageVisibility = 'toggle_image_visibility',
  ZoomIn = 'zoom_in',
  ZoomOut = 'zoom_out',
  ZoomEdit = 'zoom_edit',
  ZoomSelected = 'zoom_selected',
  ImageRotateLeft = 'image_rotate_left',
  ImageRotateRight = 'image_rotate_right',
  ImageMirror = 'image_mirror',
  ImageFlip = 'image_flip',
  ImagePreEffect = 'image_pre_effect',
  ImageTransferClicked = 'image_transfer_clicked',
  ImageTransfer = 'image_transfer',
  ClearPattern = 'clear_pattern',
  PatternShiftLeft = 'pattern_shift_left',
  PatternShiftRight = 'pattern_shift_right',
  PatternShiftUp = 'pattern_shift_up',
  PatternShiftDown = 'pattern_shift_down',
  PatternRotateLeft = 'pattern_rotate_left',
  PatternRotateRight = 'pattern_rotate_right',
  PatternMirror = 'pattern_mirror',
  PatternFlip = 'pattern_flip',
  PatternRepeatRight = 'pattern_repeat_right',
  PatternRepeatDown = 'pattern_repeat_down',
  PatternTrim = 'pattern_trim',
  CreateCategory = 'create_category',
  UpdateCategory = 'update_category',
  DeleteCategory = 'delete_category',
  CreatePalette = 'create_palette',
  UpdatePalette = 'update_palette',
  DeletePalette = 'delete_palette',
  AddColosToCustomPalette = 'add_colors_to_custom_palette',
  StartToor = 'start_tour',
  EndTour = 'end_tour',
  Help = 'help',
  ManageSubscription = 'manage_subscription',
  PurchaseSubscription = 'purchase_subscription',
  CancelSubscription = 'cancel_subscription',
  Error = 'error',
  CreateCustomColor = 'create_custom_color',
  UpdateCustomColor = 'update_custom_color',
}

export enum ImageTransferMethodEnum {
  MedianCut = 'median_cut',
  DontReduceColorsWithLib = 'dont_reduce_colors_with_lib',
  RemoveNearest = 'remove_nearest',
  WuQuantizer = 'wu_quantizer',
  OctreeQuantizer = 'octree_quantizer',
}

export enum ImageTransferDitherEnum {
  None = 'none',
  Atkinson = 'atkinson',
  Burkes = 'burkes',
  FloydSteinberg = 'floyd_steinberg',
  JarvisJudiceNinke = 'jarvis_judice_ninke',
  Sierra2 = 'sierra2',
  Sierra3 = 'sierra3',
  SierraLite = 'sierra_lite',
  StevensonArce = 'stevenson_arce',
  Stucki = 'stucki',
}

export interface BeadTransfererParameters {
  sourceImagePath: string;
  stitchingMethodId: number;
  beadWidthPixels: number;
  beadHeightPixels: number;
  columnCount: number;
  rowCount: number;
  patternOffsetX: number;
  patternOffsetY: number;
  imageOffsetX: number;
  imageOffsetY: number;
  imageWidthPixels: number;
  imageHeightPixels: number;
  imageRotationDegrees: number;
  maximumColorCount: number;
  preProcessingEffect: number;
  colorQuantizationMethod: number;
  ditheringMethod: number;
  availableColorHexCodes: string[];
}

export enum ToolbarMenuItemEnum {
  New = 'new',
  Open = 'open',
  Categories = 'categories',
  Save = 'save',
  SaveAs = 'save_as',
  Print = 'print',
  DownloadPDF = 'download_pdf',
  UploadImage = 'upload_image',
  TransferImage = 'transfer_image',
  ClearPattern = 'clear_pattern',
  ShowImage = 'show_image',
  HideImage = 'hide_image',
  Palettes = 'palettes',
  Guide = 'guide',
  Help = 'help',
}

export enum LogMessageTypeEnum {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}
